/* MANTINE CORE */
@import '@mantine/core/styles/global.css';

/* BUTTON */
@import '@mantine/core/styles/UnstyledButton.css';
@import '@mantine/core/styles/Button.css';

/* DRAWER */
@import '@mantine/core/styles/ModalBase.css';
@import '@mantine/core/styles/Drawer.css';
@import '@mantine/core/styles/Paper.css';

/* OTHER COMPONENTS */
@import '@mantine/core/styles/AppShell.css';
@import '@mantine/core/styles/Group.css';
@import '@mantine/core/styles/Burger.css';
@import '@mantine/core/styles/ActionIcon.css';
@import '@mantine/core/styles/Title.css';
@import '@mantine/core/styles/Center.css';
@import '@mantine/core/styles/NavLink.css';
@import '@mantine/core/styles/Container.css';
@import '@mantine/core/styles/Indicator.css';
@import '@mantine/core/styles/Progress.css';
@import '@mantine/core/styles/Flex.css';

/* LOADER OVERLAY */
@import '@mantine/core/styles/Loader.css';
@import '@mantine/core/styles/Overlay.css';
@import '@mantine/core/styles/LoadingOverlay.css';

/* SPOTLIGHT */
@import 'spotlight.js/../../css/spotlight.css';

/* MAPLIBRE */
@import 'maplibre-gl/dist/maplibre-gl.css';

@import '../utils/fonts/Rubik.css';

.maplibregl-ctrl button.maplibregl-ctrl-compass-on .maplibregl-ctrl-icon {
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-compass' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='%2333b5e5' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M8 16l2 -6l6 -2l-2 6l-6 2' /%3E%3Cpath d='M12 12m-9 0a9 9 0 1 0 18 0a9 9 0 1 0 -18 0' /%3E%3Cpath d='M12 3l0 2' /%3E%3Cpath d='M12 19l0 2' /%3E%3Cpath d='M3 12l2 0' /%3E%3Cpath d='M19 12l2 0' /%3E%3C/svg%3E%0A");
}
.maplibregl-ctrl button.maplibregl-ctrl-compass-off .maplibregl-ctrl-icon {
    background-image: url("data:image/svg+xml,%0A%3Csvg xmlns='http://www.w3.org/2000/svg' class='icon icon-tabler icon-tabler-compass-off' width='24' height='24' viewBox='0 0 24 24' stroke-width='2' stroke='currentColor' fill='none' stroke-linecap='round' stroke-linejoin='round'%3E%3Cpath stroke='none' d='M0 0h24v24H0z' fill='none'/%3E%3Cpath d='M13 9l3 -1l-1 3m-1 3l-6 2l2 -6' /%3E%3Cpath d='M20.042 16.045a9 9 0 0 0 -12.087 -12.087m-2.318 1.677a9 9 0 1 0 12.725 12.73' /%3E%3Cpath d='M12 3v2' /%3E%3Cpath d='M12 19v2' /%3E%3Cpath d='M3 12h2' /%3E%3Cpath d='M19 12h2' /%3E%3Cpath d='M3 3l18 18' /%3E%3C/svg%3E ");
}

.overlay-opac::before {
    content: "";
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    background-color: rgba(0, 0, 0, 0.80);
}
